const DEFAULTS = {
  USER_MS_URL: 'https://dev-api.landers.ph/user',
  TRANSACTION_MS_URL: 'https://dev-api.landers.ph/transaction',
  PRODUCT_MS_URL: 'https://dev-api.landers.ph/product',
  CORE_MS_URL: 'https://dev-api.landers.ph',
  CART_MS_URL: 'https://dev-api.landers.ph/cart',
  CART_MS_CONTAINER_POD_URL: 'https://cart-ms.dev.landers.ph/cart',
};

export const USER_MS_URL =
  process.env.REACT_APP_LAMBDA_USER_MS_URL || DEFAULTS.USER_MS_URL;

export const TRANSACTION_MS_URL =
  process.env.REACT_APP_LAMBDA_TRANSACTION_MS_URL ||
  DEFAULTS.TRANSACTION_MS_URL;

export const PRODUCT_MS_URL =
  process.env.REACT_APP_LAMBDA_PRODUCT_MS_URL || DEFAULTS.PRODUCT_MS_URL;

export const CORE_MS_URL =
  process.env.REACT_APP_LAMBDA_CORE_MS_URL || DEFAULTS.CORE_MS_URL;

export const CART_MS_URL =
  process.env.REACT_APP_LAMBDA_CART_MS_URL || DEFAULTS.CART_MS_URL;

export const CART_MS_CONTAINER_POD_URL =
  process.env.REACT_APP_LAMBDA_CART_MS_CONTAINER_POD_URL ||
  DEFAULTS.CART_MS_CONTAINER_POD_URL;
