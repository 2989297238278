import { produce } from 'immer';
import cartHelper from 'containers/Checkout/helpers/cart';
import { get, set, map, cloneDeep } from 'lodash';
import localStorage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { getAccessToken } from 'utils/request';
import * as authenticationConstant from 'containers/Authentication/constants';
import * as promotionHelper from 'containers/Checkout/helpers/promotions';

import * as constants from './constants';
import * as checkoutCartConstants from 'containers/Checkout/screens/CheckoutCart/constants';
import { getBizRebateAmount } from 'utils/helpers';

const getCartItemsCount = (items) => {
  let count = 0;

  for (let item of items) {
    if (
      item?.promotion?.promotion_type &&
      ['buyxgety', 'bundle_group'].includes(item?.promotion?.promotion_type)
    ) {
      const { buy } = item;
      const [_item] = buy;
      count += _item.qty;
    } else {
      count += item.qty;
    }
  }

  return count;
};

// eslint-disable-next-line
const mapItemIdToCart = (oldItems, newItems) => {
  return newItems.map((currentItem) => {
    if (
      currentItem.product_type === 'customer_membership' ||
      currentItem.item_id ||
      ['buyxgety', 'bundle_group'].includes(
        get(currentItem, 'promotion.promotion_type'),
      )
    ) {
      return currentItem;
    }

    const obj = oldItems.find(({ sku }) => currentItem.sku === sku);

    if (
      obj &&
      (obj.product_type === 'customer_membership' ||
        obj.item_id ||
        ['buyxgety', 'bundle_group'].includes(
          get(obj, 'promotion.promotion_type'),
        ))
    ) {
      return {
        ...obj,
        qty: currentItem.qty,
      };
    } else {
      return currentItem;
    }

    // return obj ? { ...currentItem, item_id: obj.item_id } : currentItem
  });
};

const persistConfig = {
  key: 'checkout',
  storage: localStorage,
  whitelist: [
    'cartId',
    'cartMaskId',
    'selectedTimeslot',
    'shippingAddress',
    'couponCode',
    'cartNeedSync',
    'orderId',
    'cancellationOption',
    'bizRebateAmount',
    'tempCartTotals',
  ],
  debounce: 3000,
};

export const initialState = {
  newUpdatedQtys: null,
  oldUpdatedQtys: null,
  cartData: {
    items: [],
  },
  cartItemsCount: '',
  cartTotals: {},
  neededMergedItems: null,
  invalidCartItems: null,
  cartMaskId: '',
  cartId: null,
  productId: null,
  productQty: 0,
  shippingMethods: [],
  paymentMethods: [],
  mediaPath: '',
  deliveryTimeslots: [],
  selectedTimeslot: null,
  selectedPaymentMethod: null,
  storePickupAddress: null,
  progressBar: null,
  shippingAddress: null,
  orderId: 0,
  shoppingNow: null,
  recommendProducts: [],
  subscribeProduct: null,
  createdOrder: null,
  couponCode: '',
  dataCheckCoupon: {
    success: false,
    error: false,
  },
  cartNeedSync: false,
  cancellationOption: '',
  defaultCancellationOption: '',
  feeTooltipVisible: false,
  isRestoreCartProcessing: false,
  lastOrderOOS: '',
  isSubmittedPayment: false,
  storesTimeSlots: {},
  selectedPaymentCard: null,
  paymentCards: [],
  showSlowCartLoading: false,
  productRecommendations1: [],
  productRecommendations2: [],
  isSyncingProduct: false,
  cartRules: [],
  payPlaceOrderResponse: {},
  tempItems: [],
  bizRebateAmount: 0,
  // tempCartTotals is only being used in business rebate
  tempCartTotals: {},
};

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.CREATE_CART:
        break;
      case constants.CREATE_CART_SUCCESS:
        if (getAccessToken()) {
          newState.cartId = action.payload;
        } else {
          newState.cartMaskId = action.payload;
        }

        break;
      case constants.CREATE_CART_FAILED:
        break;

      case constants.GET_CART_INFO:
        break;
      case constants.GET_CART_INFO_SUCCESS:
        const newCartRules = [];
        const cartRules = state.cartRules;

        cartRules.forEach((cartRule) => {
          const foundCartRule = action.payload.items.find(
            (item) => item.sku === cartRule.temp_id,
          );

          if (!!foundCartRule) {
            newCartRules.push(cartRule);
          }
        });

        const _newCartData = promotionHelper.applyRulesToCart(
          action.payload,
          newCartRules,
          null,
        );
        newState.cartId = action.payload.id;
        newState.cartData = { ...action.payload };
        newState.cartData.items = _newCartData.items;
        newState.cartData.promotion_items = _newCartData.promotion_items;
        newState.cartRules = newCartRules;

        break;
      case constants.GET_CART_INFO_FAILED:
        break;

      case constants.CALCULATE_BIZ_REBATE:
        newState.bizRebateAmount = getBizRebateAmount(
          cloneDeep(newState.cartData.promotion_items),
        );
        break;
      case constants.CLEAR_BIZ_REBATE:
        newState.bizRebateAmount = 0;
        newState.tempCartTotals = {};
        break;

      case constants.GET_CART_TOTALS:
        newState.cartTotals.isLoading = true;
        break;
      case constants.GET_CART_TOTALS_SUCCESS:
        const { isSetTempItems = true, ...payload } = action.payload;

        newState.cartTotals = payload;
        newState.couponCode = get(action, 'payload.coupon_code');
        newState.cartTotals.isLoading = false;

        if (isSetTempItems) {
          newState.tempCartTotals = {
            fee: payload.fee,
            base_fee: payload.base_fee,
            other_delivery_fee: payload.other_delivery_fee,
            base_other_delivery_fee: payload.base_other_delivery_fee,
            base_shipping_amount: payload.base_shipping_amount,
            shipping_amount: payload.shipping_amount,
            subtotal: payload.subtotal,
            grand_total: payload.grand_total,
            total_savings: payload.total_savings,
            is_all_solone: payload.is_all_solone,
            is_all_gtc: payload.is_all_gtc,
          };
        }

        break;
      case constants.GET_CART_TOTALS_FAILED:
        newState.cartTotals.isLoading = false;
        break;
      case constants.SET_LOADING_CART_TOTALS:
        newState.cartTotals.isLoading = action.payload;
        break;

      case constants.VALIDATE_CART_ITEMS:
        break;
      case constants.VALIDATE_CART_ITEMS_SUCCESS:
        newState.invalidCartItems = action.payload;
        break;
      case constants.VALIDATE_CART_ITEMS_FAILED:
        break;

      case constants.SYNC_CART_SERVER:
        break;
      case constants.SYNC_CART_SERVER_SUCCESS:
        const resultItems = get(action, 'payload.items', []);

        const newCartData = promotionHelper.applyRulesToCart(
          action.payload,
          state.cartRules,
          null,
        );

        newState.cartData.items = newCartData.items;
        newState.cartData.promotion_items = newCartData.promotion_items;

        newState.cartItems = newCartData.items;
        newState.cartItemsCount = getCartItemsCount(resultItems);

        break;
      case constants.SET_CART_ITEMS_COUNT:
        newState.cartItemsCount = getCartItemsCount(action.payload);
        break;
      case constants.SYNC_CART_SERVER_FAILED:
        break;

      case constants.GET_CART_RULES:
        break;
      case constants.GET_CART_RULES_SUCCESS: {
        newState.cartRules = get(action, 'payload.rules');
        newState.cartItemsCount = get(action, 'payload.cart.items_qty', 0);
        newState.cartData = { ...action.payload.cart };
        if (!action.payload.isSyncCart) {
          newState.cartItemsCount = get(action, 'payload.cart.items_qty', 0);
        }
        break;
      }
      case constants.GET_CART_RULES_FAILED:
        break;

      case constants.CLEAR_CART_RULES: {
        newState.cartData = initialState.cartData;
        newState.cartItemsCount = 0;
        break;
      }

      case constants.GET_DELIVERY_TIMESLOTS:
        break;
      case constants.GET_DELIVERY_TIMESLOTS_SUCCESS:
        newState.selectedPaymentMethod = null;
        newState.deliveryTimeslots = action.payload;
        break;
      case constants.GET_DELIVERY_TIMESLOTS_FAILED:
        break;

      case constants.SELECT_DELIVERY_TIMESLOT:
        newState.selectedTimeslot = action.timeslot;
        break;

      case constants.VALIDATE_DELIVERY_TIMESLOT:
        break;
      case constants.VALIDATE_DELIVERY_TIMESLOT_SUCCESS:
        break;
      case constants.VALIDATE_DELIVERY_TIMESLOT_FAILED:
        break;

      case constants.GET_SHIPPING_METHODS:
        break;
      case constants.GET_SHIPPING_METHODS_SUCCESS:
        break;
      case constants.GET_SHIPPING_METHODS_FAILED:
        break;

      case constants.ESTIMATE_SHIPPING_METHODS:
        break;
      case constants.ESTIMATE_SHIPPING_METHODS_SUCCESS:
        break;
      case constants.ESTIMATE_SHIPPING_METHODS_FAILED:
        break;

      case constants.SET_SHIPPING_ADDRESS:
        newState.shippingAddress = action.address;
        break;

      case constants.UPDATE_SHIPPING_ADDRESS:
        break;
      case constants.UPDATE_SHIPPING_ADDRESS_SUCCESS:
        break;
      case constants.UPDATE_SHIPPING_ADDRESS_FAILED:
        break;

      case constants.SET_PAYMENT_METHOD:
        newState.selectedPaymentMethod = action.method;
        break;

      case constants.UPDATE_PAYMENT_INFORMATION:
        break;
      case constants.UPDATE_PAYMENT_INFORMATION_SUCCESS:
        newState.orderId = action.payload;
        newState.couponCode = '';
        newState.cartMaskId = '';
        newState.cartId = '';
        break;
      case constants.UPDATE_PAYMENT_INFORMATION_FAILED:
        newState.orderId = 0;
        break;

      case constants.GET_ORDER_BYID:
        break;
      case constants.GET_ORDER_BYID_SUCCESS:
        newState.paymentMethods = [];
        newState.createdOrder = action.payload;
        break;
      case constants.GET_ORDER_BYID_FAILED:
        newState.createdOrder = {};
        break;
      case constants.CLEAR_ORDER_ID:
        newState.orderId = 0;
        break;

      case constants.GET_PAYMENT_METHODS:
        break;
      case constants.GET_PAYMENT_METHODS_SUCCESS:
        newState.paymentMethods = action.payload;
        break;
      case constants.GET_PAYMENT_METHODS_FAILED:
        break;
      case constants.GET_MEDIA_PATH_SUCCESS:
        newState.mediaPath = action.payload;
        break;

      case constants.APPLY_COUPON:
        break;
      case constants.APPLY_COUPON_SUCCESS:
        newState.couponCode = action.payload.couponCode;
        break;
      case constants.APPLY_COUPON_FAILED:
        newState.dataCheckCoupon = action.error.dataCheckCoupon;
        break;

      case constants.GET_VOUCHER_MESSAGE_SUCCESS:
        newState.dataCheckCoupon = action.payload;
        break;

      case checkoutCartConstants.GET_PROGRESSBAR:
        break;
      case checkoutCartConstants.GET_PROGRESSBAR_SUCCESS:
        newState.progressBar = action.payload;
        break;
      case checkoutCartConstants.GET_PROGRESSBAR_FAILED:
        newState.progressBar = {};
        break;

      case checkoutCartConstants.GET_SHOPPING_NOW:
        break;
      case checkoutCartConstants.GET_SHOPPING_NOW_SUCCESS:
        newState.shoppingNow = action.payload;
        break;
      case checkoutCartConstants.GET_SHOPPING_NOW_FAILED:
        newState.shoppingNow = {};
        break;

      case checkoutCartConstants.GET_RECOMMEND_PRODUCT:
        break;
      case checkoutCartConstants.GET_RECOMMEND_PRODUCT_SUCCESS:
        newState.recommendProducts = action.payload;
        break;
      case checkoutCartConstants.GET_RECOMMEND_PRODUCT_FAILED:
        newState.recommendProducts = [];
        break;

      case constants.SYNC_CART_CLIENT: {
        const Itemdetails = action.products;
        if (Itemdetails.length > 0) {
          Itemdetails.forEach((val) => {
            const valueIndex = state.tempItems.findIndex(
              (item) => item.sku === val?.data?.sku,
            );

            if (val?.qty > 0 && valueIndex === -1) {
              state.tempItems.push({
                product_id: val?.data?.id,
                qty: val?.qty,
                sku: val?.data?.sku,
              });
            }

            if (val?.qty > 0 && valueIndex >= 0) {
              state.tempItems[valueIndex] = {
                ...state.tempItems[valueIndex],
                qty: val?.qty,
              };
            }

            if (val?.qty < 1) {
              state.tempItems.splice(valueIndex, 1);
            }
          });
        }

        const includeMembershipItem = get(
          action,
          'params.includeMembershipItem',
        );

        const newCart = cartHelper.syncCartClient(
          state.cartData,
          action.products,
        );

        let updatedQtys = {
          cartId: get(state, 'cartId'),
          cartItems: [],
          emptyCart: 0,
        };

        updatedQtys.cartItems = map(newCart.items, (item) => ({
          product_id:
            item?.product_id ||
            item?.extension_attributes?.product_data?.id ||
            item?.extension_attributes?.product_data?.product_id,
          qty: item.qty,
          type_id: includeMembershipItem
            ? ''
            : get(item, 'extension_attributes.product_data.type_id'),
          item_id: item.item_id || item.product_id,
          product_type: item.product_type,
          ...(item?.special_instructions &&
            item.special_instructions !== '' && {
              special_instructions: item.special_instructions,
            }),
        }));

        newState.newUpdatedQtys = updatedQtys;
        newState.cartData = { ...newCart };

        if (!getAccessToken()) {
          newState.neededMergedItems = newCart.items;
        } else {
          newState.neededMergedItems = null;
        }
        break;
      }

      case constants.SYNC_PRODUCT_THEN_GOCHECKOUT: {
        break;
      }

      case constants.REMOVE_ALLPRODUCTS_INCART: {
        const { id } = state.cartData;
        let newCart = cartHelper.removeAllProductsInCart(state.cartData);
        newCart.id = id;

        let updatedQtys = {
          cartId: id,
          cartItems: [],
          emptyCart: 1,
        };
        updatedQtys.cartItems = map(newCart.items, (item) => ({
          product_id: get(item, 'extension_attributes.product_data.id'),
          qty: item.qty,
        }));

        newState.newUpdatedQtys = updatedQtys;
        newState.cartData = { ...newCart };
        newState.cartItemsCount = get(action, 'payload.items_qty', 0);
        newState.tempItems = [];
        break;
      }
      case constants.REMOVE_PRODUCT_IN_CART: {
        newState.cartId = state.cartId;
        newState.productId = state.productId;
        break;
      }

      case constants.REMOVE_MULTIPLE_PRODUCTS_IN_CART: {
        newState.cartId = state.cartId;
        newState.invalidCartItems = state.invalidCartItems;
        break;
      }

      case constants.RESTORE_CART:
        newState.orderId = 0;
        newState.createdOrder = null;
        newState.selectedTimeslot = null;
        break;

      case constants.SET_CART_AS_NEED_SYNC:
        newState.cartNeedSync = action.status;
        break;

      case authenticationConstant.SIGN_UP_SUCCESS:
        newState.cartId = get(action, 'payload.cart_id');
        set(newState, 'cartData.id', +get(action, 'payload.cart_id', 0));
        break;

      case authenticationConstant.LOGIN_SUCCESS:
        newState.cartId = get(action, 'payload.cart_id');
        set(newState, 'cartData.id', +get(action, 'payload.cart_id', 0));
        break;

      case constants.REMOVE_SHIPPING_ADDRESS_INVALID_QUANTITIES:
        const extension_attributes =
          get(state, 'shippingAddress.extension_attributes') || {};
        const source_stock_code = get(
          extension_attributes,
          'address_validation_data.validate_cart_data.source_stock_code',
          '',
        );
        extension_attributes.address_validation_data = {
          valid_to_ship: true,
          validate_cart_data: {
            show_dl_popup: null,
            allow_checkout: true,
            invalid_qty: [],
            invalid_skus: [],
            source_stock_code,
            error: false,
            message: null,
          },
        };
        newState.shippingAddress = {
          ...state.shippingAddress,
          extension_attributes,
        };
        newState.invalidCartItems = [];
        break;

      case constants.GET_LAST_ORDER_OOS:
        break;
      case constants.GET_LAST_ORDER_OOS_SUCCESS:
        newState.lastOrderOOS = action.payload;
        break;
      case constants.GET_LAST_ORDER_OOS_FAILED:
        newState.lastOrderOOS = '';
        break;

      case constants.SET_CANCELLATION_OPTION:
        newState.cancellationOption = action.payload;
        break;
      case constants.SET_DEFAULT_CANCELLATION_OPTION:
        newState.defaultCancellationOption = action.payload;
        break;
      case constants.SET_STORE_PICKUP_ADDRESS:
        newState.storePickupAddress = action.payload;
        break;
      case constants.SET_FEE_TOOLTIP_VISIBILITY:
        newState.feeTooltipVisible = action.payload.visible;
        break;
      case constants.IS_RESTORE_CART_PROCESSING:
        newState.isRestoreCartProcessing = action.payload;
        break;
      case constants.IS_SUBMITTED_PAYMENT:
        newState.isSubmittedPayment = action.payload;
        break;

      case constants.GET_STORES_TIME_SLOTS:
        break;
      case constants.GET_STORES_TIME_SLOTS_SUCCESS:
        state.storesTimeSlots[action.payload.sourcecode] =
          action.payload.timeSlots;
        newState.storesTimeSlots = { ...state.storesTimeSlots };
        break;
      case constants.GET_STORES_TIME_SLOTS_FAILED:
        break;

      case constants.SET_PAYMENT_CARD:
        newState.selectedPaymentCard = action.card;
        break;

      case constants.GET_PAYMENT_CARDS:
        break;
      case constants.GET_PAYMENT_CARDS_SUCCESS:
        newState.paymentCards = action.payload || [];
        break;
      case constants.GET_PAYMENT_CARDS_FAILED:
        break;

      case constants.ADD_PAYMENT_CARDS:
        break;
      case constants.ADD_PAYMENT_CARDS_SUCCESS:
        newState.paymentCards = action.payload || [];
        break;
      case constants.ADD_PAYMENT_CARDS_FAILED:
        break;

      case constants.SHOW_SLOW_CART_LOADING:
        newState.showSlowCartLoading = true;
        break;
      case constants.HIDE_SLOW_CART_LOADING:
        newState.showSlowCartLoading = false;
        break;

      case checkoutCartConstants.GET_PRODUCT_RECOMMENDATION_1_SUCCESS:
        newState.productRecommendations1 = action.payload;
        break;

      case checkoutCartConstants.GET_PRODUCT_RECOMMENDATION_2_SUCCESS:
        newState.productRecommendations2 = action.payload;
        break;

      case constants.REMOVE_VOUCHER_MESSAGE:
        newState.dataCheckCoupon = { success: false, error: false };
        break;
      case constants.SET_IS_SYNCING_PRODUCT:
        newState.isSyncingProduct = action.payload;
        break;
      case constants.SAVE_PAY_PLACE_ORDER:
        break;
      case constants.SAVE_PAY_PLACE_ORDER_SUCCESS:
        newState.payPlaceOrderResponse = action.payload;
        break;
      case constants.UPDATE_TEMP_ITEMS:
        newState.tempItems = action.payload;
        break;

      default:
        break;
    }
  });

export default persistReducer(persistConfig, reducer);
