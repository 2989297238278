import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'global-config';
import { history } from 'configureStore';
import { getAccessToken, getStoreCode, setAccessToken } from 'utils/request';
import { BaseQueryType } from './types';

const AUTH_API_PATHS = [
  '/me/password',
  '/customer/login',
  '/landersapi-customer-register',
];

const baseQueryInterceptor: BaseQueryType = async (args, api, extraOptions) => {
  const baseUrl = config.apiUrl;
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      headers.set('Authorization', `Bearer ${getAccessToken()}`);
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      headers.set('sourceCode', getStoreCode());

      return headers;
    },
  });

  const isNotAuthAPI = AUTH_API_PATHS.every(
    (path) => !args?.url.includes(path),
  );

  const result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401 && isNotAuthAPI) {
    setAccessToken('');
    history.push('/', { isTokenExpired: true });
  }

  return result;
};

export const mgtApi = createApi({
  baseQuery: baseQueryInterceptor,
  endpoints: () => ({}),
  reducerPath: 'mgtApi',
});
