import axios from 'axios';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import Config from 'global-config';
import { onSendLogger } from './slice';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: onSendLogger,
  effect: async (action) => {
    try {
      const tokenUrl = `${Config.lambdaApiUrl}/auth/token`;
      const tokenRes = await axios.post(
        tokenUrl,
        {},
        {
          headers: {
            scope: 'web',
            Authorization: `Basic ${process.env.REACT_APP_GENERIC_LOGGER_AUTH}`,
          },
        },
      );

      if (tokenRes.data?.data && action?.payload) {
        const loggerUrl = `${Config.lambdaApiUrl}/logger`;
        await axios.post(
          loggerUrl,
          {
            topic: 'landers/web',
            toDeconstruct: true,
            message: JSON.stringify(action.payload),
          },
          {
            headers: {
              scope: 'web',
              Authorization: `Bearer ${tokenRes.data.data}`,
            },
          },
        );
      }
    } catch (e) {}
  },
});

export default listenerMiddleware;
