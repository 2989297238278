import * as constant from './constants';

// SHOW/HIDE components/LoaderIndicator
export function showLoader(payload = {}) {
  return {
    type: constant.SHOW_LOADER,
    payload,
  };
}

export function hideLoader() {
  return {
    type: constant.HIDE_LOADER,
  };
}

// SHOW/HIDE partial loader components
export function showPartialLoader(payload = {}) {
  return {
    type: constant.SHOW_PARTIAL_LOADER,
    payload,
  };
}

// LOAD_SIDEBAR
export function loadSideBar() {
  return {
    type: constant.LOAD_SIDEBAR,
  };
}
export function loadSideBarSuccess(payload) {
  return {
    type: constant.LOAD_SIDEBAR_SUCCESS,
    payload,
  };
}
export function loadSideBarFailed(error) {
  return {
    type: constant.LOAD_SIDEBAR_FAILED,
    error,
  };
}

export const loadCategoryLeft = () => ({
  type: constant.LOAD_CATEGORY_MENU_LEFT,
});

export const loadSideAds = () => ({
  type: constant.LOAD_SIDEADS,
});

// LOAD_SUB_MENU_TOP
export function loadSubMenuTop() {
  return {
    type: constant.LOAD_SUBMENUTOP,
  };
}
export function loadSubMenuTopSuccess(payload) {
  return {
    type: constant.LOAD_SUBMENUTOP_SUCCESS,
    payload,
  };
}
export function loadSubMenuTopFailed(error) {
  return {
    type: constant.LOAD_SUBMENUTOP_FAILED,
    error,
  };
}

// CHECK_LOCATION
export function checkLocation() {
  return {
    type: constant.CHECK_LOCATION,
  };
}

export function checkLocationSuccess(payload) {
  return {
    type: constant.CHECK_LOCATION_SUCCESS,
    payload,
  };
}

export function checkLocationFailed(error) {
  return {
    type: constant.CHECK_LOCATION_FAILED,
    error,
  };
}

export function clearCurrentLocation() {
  return {
    type: constant.CLEAR_CURRENT_LOCATION,
  };
}

export function getUserInfo(requestData = {}) {
  return {
    type: constant.GET_USER_INFO,
    needValidateAddress: requestData.needValidateAddress,
    needValidateMembership: requestData.needValidateMembership,
    needValidateDefaultShipping: requestData.needValidateDefaultShipping,
    membershipRequest: requestData.membershipRequest,
    needUpdateID: requestData.needUpdateID,
    isShowLoading: !requestData?.isShowLoading
      ? requestData.isShowLoading
      : true,
    method: requestData.method,
    isFromSignUp: requestData?.isFromSignUp,
    isSubscribed: requestData?.isSubscribed,
  };
}
export function updateShippingAddress() {
  return {
    type: constant.UPDATE_SHIPPING_ADDRESS,
  };
}

export function getUserInfoSuccess(payload) {
  return {
    type: constant.GET_USER_INFO_SUCCESS,
    payload,
  };
}

export function getUserInfoFailed(error) {
  return {
    type: constant.GET_USER_INFO_FAILED,
    error,
  };
}

// ADDRESS MODAL ACTION
export function loadProvince(payload) {
  return {
    type: constant.LOAD_PROVINCE,
    payload,
  };
}
export function loadProvinceSuccess(payload) {
  return {
    type: constant.LOAD_PROVINCE_SUCCESS,
    payload,
  };
}
export function loadProvinceFailed(payload) {
  return {
    type: constant.LOAD_PROVINCE_FAILED,
    payload,
  };
}
export function loadCity(payload) {
  return {
    type: constant.LOAD_CITY,
    payload,
  };
}
export function loadCitySuccess(payload) {
  return {
    type: constant.LOAD_CITY_SUCCESS,
    payload,
  };
}
export function loadCityFailed(payload) {
  return {
    type: constant.LOAD_CITY_FAILED,
    payload,
  };
}
export function loadBarangay(payload) {
  return {
    type: constant.LOAD_BARANGAY,
    payload,
  };
}
export function loadBarangaySuccess(payload) {
  return {
    type: constant.LOAD_BARANGAY_SUCCESS,
    payload,
  };
}
export function loadBarangayFailed(payload) {
  return {
    type: constant.LOAD_BARANGAY_FAILED,
    payload,
  };
}

export function loadFooter(payload) {
  return {
    type: constant.LOAD_FOOTER,
    payload,
  };
}
export function loadFooterSuccess(payload) {
  return {
    type: constant.LOAD_FOOTER_SUCCESS,
    payload,
  };
}
export function loadFooterFailed(payload) {
  return {
    type: constant.LOAD_FOOTER_FAILED,
    payload,
  };
}

export function showSideBar(payload) {
  return {
    type: constant.SHOW_SIDE_BAR,
    payload,
  };
}

export function hideSideBar() {
  return {
    type: constant.HIDE_SIDE_BAR,
  };
}

export function showSearchMode(payload) {
  return {
    type: constant.SHOW_SEARCH_MODE,
    payload,
  };
}

// SUBSCRIBE_PRODUCT
export function getSubscribeProduct(payload) {
  return {
    type: constant.SUBSCRIBE_PRODUCT,
    payload,
  };
}

export function getSubscribeProductSuccess(payload) {
  return {
    type: constant.SUBSCRIBE_PRODUCT_SUCCESS,
    payload,
  };
}

export function getSubscribeProductFailed(error) {
  return {
    type: constant.SUBSCRIBE_PRODUCT_FAILED,
    error,
  };
}

export function logout(payload) {
  return {
    type: constant.LOGOUT,
    payload,
  };
}

export function setPersistorLoaded() {
  return {
    type: constant.SET_PERSISTOR_LOADED,
  };
}

export function loadCountryNameSuccess(payload) {
  return {
    type: constant.LOAD_COUNTRY_NAME_SUCCESS,
    payload,
  };
}

// get list static page (all cms)
export function loadAllCms(payload) {
  return {
    type: constant.LOAD_ALL_CMS,
    payload,
  };
}

export function loadAllCmsSuccess(payload) {
  return {
    type: constant.LOAD_ALL_CMS_SUCCESS,
    payload,
  };
}

export function loadAllCmsFailed(payload) {
  return {
    type: constant.LOAD_ALL_CMS_FAILED,
    payload,
  };
}

export function loadGlobalConfig() {
  return {
    type: constant.LOAD_GLOBAL_CONFIG,
  };
}

export function loadGlobalConfigSuccess(payload) {
  return {
    type: constant.LOAD_GLOBAL_CONFIG_SUCCESS,
    payload,
  };
}
export function loadGlobalConfigFailed(error) {
  return {
    type: constant.LOAD_GLOBAL_CONFIG_FAILED,
    error,
  };
}

export function loadStoreAddresses(payload) {
  return {
    type: constant.LOAD_STORE_ADDRESSES,
    payload,
  };
}
export function loadStoreAddressesSuccess(payload) {
  return {
    type: constant.LOAD_STORE_ADDRESSES_SUCCESS,
    payload,
  };
}
export function loadStoreAddressesFailed(payload) {
  return {
    type: constant.LOAD_STORE_ADDRESSES_FAILED,
    payload,
  };
}

export function loadSpecialCategoryIDSuccess(payload) {
  return {
    type: constant.LOAD_SPECIAL_CATEGORY_ID_SUCCESS,
    payload,
  };
}

export function loadOutOfStockConfig(payload) {
  return {
    type: constant.LOAD_OUT_OF_STOCK_CONFIG,
    payload,
  };
}

export function loadPreferredBranches(payload) {
  return {
    type: constant.LOAD_PREFERRED_BRANCHES,
    payload,
  };
}

export function loadFooterConfig(payload) {
  return {
    type: constant.LOAD_FOOTER_CONFIG,
    payload,
  };
}

export function updateFCMToken(payload = {}) {
  return {
    type: constant.UPDATE_FCM_TOKEN,
    payload,
  };
}
export function deleteFCMToken(payload = {}) {
  return {
    type: constant.DELETE_FCM_TOKEN,
    payload,
  };
}

export function loadDeliverySlotVerbiage(payload) {
  return {
    type: constant.LOAD_DELIVERY_SLOT_VERBIAGE,
    payload,
  };
}

export function loadSearchAndAutoSuggest(payload) {
  return {
    type: constant.LOAD_SEARCH_AND_AUTO_SUGGEST,
    payload,
  };
}

export function getStoreMapping() {
  return {
    type: constant.GET_STORE_VIEW_MAPPING,
  };
}

export function setStoreMappingSuccess(payload) {
  return {
    type: constant.SET_STORE_VIEW_MAPPING_SUCCESS,
    payload,
  };
}

export function rehydrateStoreViewCode(payload) {
  return {
    type: constant.REHYDRATE_STORE_VIEW_CODE,
    payload,
  };
}

/**
 *
 * @param {string} pathname
 * @param {boolean} isStoreCodeChanged
 * @returns {Object}
 */
export function syncCartAddressChange(pathname, isStoreCodeChanged) {
  return {
    type: constant.SYNC_CART_ADDRESS_CHANGED,
    pathname,
    isStoreCodeChanged,
  };
}

export function setYellowAIPayload(payload = null) {
  return {
    type: constant.SET_YELLOW_AI_PAYLOAD,
    payload,
  };
}

export function setYellowAiPayloadSuccess(payload) {
  return {
    type: constant.SET_YELLOW_AI_PAYLOAD_SUCCESS,
    payload,
  };
}

export function setIsShowChatNudge(payload) {
  return {
    type: constant.SET_IS_SHOW_CHAT_NUDGE,
    payload,
  };
}

export function reloadApiForPage() {
  return {
    type: constant.RELOAD_API_OF_PAGE,
  };
}

export function updateCTUserProfile(payload) {
  return {
    type: constant.UPDATE_CT_USER_PROFILE,
    payload,
  };
}
