import config from 'global-config';

// Get shopping now list
export const GET_SHOPPING_NOW = 'landers/CheckoutCart/GET_SHOPPING_NOW';
export const GET_SHOPPING_NOW_SUCCESS =
  'landers/CheckoutCart/GET_SHOPPING_NOW_SUCCESS';
export const GET_SHOPPING_NOW_FAILED =
  'landers/CheckoutCart/GET_SHOPPING_NOW_FAILED';

// Get progressbar info
export const GET_PROGRESSBAR = 'landers/CheckoutCart/GET_PROGRESSBAR';
export const GET_PROGRESSBAR_SUCCESS =
  'landers/CheckoutCart/GET_PROGRESSBAR_SUCCESS';
export const GET_PROGRESSBAR_FAILED =
  'landers/CheckoutCart/GET_PROGRESSBAR_FAILED';

// Get recomment product
export const GET_RECOMMEND_PRODUCT = 'recomment products';
export const GET_RECOMMEND_PRODUCT_SUCCESS = 'recomment products success';
export const GET_RECOMMEND_PRODUCT_FAILED = 'recomment products failed';

export const GET_PRODUCT_RECOMMENDATION_1 =
  'landers/Checkout/GET_PRODUCT_RECOMMENDATION_1';
export const GET_PRODUCT_RECOMMENDATION_1_SUCCESS =
  'landers/Checkout/GET_PRODUCT_RECOMMENDATION_1_SUCCESS';

export const GET_PRODUCT_RECOMMENDATION_2 =
  'landers/Checkout/GET_PRODUCT_RECOMMENDATION_2';
export const GET_PRODUCT_RECOMMENDATION_2_SUCCESS =
  'landers/Checkout/GET_PRODUCT_RECOMMENDATION_2_SUCCESS';

const RECOMMENDATION1_PROD =
  'https://landers-static-assets.s3.ap-southeast-1.amazonaws.com/marketing/experience/recommendation-1.json';
const RECOMMENDATION1_STAGING =
  'https://landers-static-assets.s3.ap-southeast-1.amazonaws.com/marketing/experience/staging/recommendation-1.json';

export const RECOMMENDATION1_URL =
  config.baseUrl === 'https://staging.landers.ph'
    ? RECOMMENDATION1_STAGING
    : RECOMMENDATION1_PROD;

const RECOMMENDATION2_PROD =
  'https://landers-static-assets.s3.ap-southeast-1.amazonaws.com/marketing/experience/recommendation-2.json';
const RECOMMENDATION2_STAGING =
  'https://landers-static-assets.s3.ap-southeast-1.amazonaws.com/marketing/experience/staging/recommendation-2.json';

export const RECOMMENDATION2_URL =
  config.baseUrl === 'https://staging.landers.ph'
    ? RECOMMENDATION2_STAGING
    : RECOMMENDATION2_PROD;
