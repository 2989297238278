import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { isMobile } from 'react-device-detect';

export const trackingEvent = {
  addToCart: 'add_to_cart',
  splashAds: 'splash_ads',
  clickNotification: 'click_notification',
  clickReorder: 'click_reorder',
  purchase: 'purchase',
  removeFromCart: 'remove_from_cart',
  clickSearch: 'click_search',
  searchKey: 'search_key',
  clickCart: 'click_cart',
  viewMiniCart: 'view_mini_cart',
  clickEmptyCart: 'click_empty_cart',
  clickCheckout: 'click_checkout',
  clickApplyMembership: 'click_apply_membership',
  linkMembership: 'link_membership',
  clickBanner: 'click_banner',
  signupCompleted: 'signup_completed',
  clickCard: 'click_card',
  savedCard: 'saved_card',
  removeCard: 'remove_card',
  pastPurchase: 'past_purchase',
  viewSearchResults: 'view_search_results',
  viewItem: 'view_item',
  beginCheckout: 'begin_checkout',
  userLogin: 'user_login',
  addShippingInfo: 'add_shipping_info',
  addPaymentInfo: 'add_payment_info',
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const initFirebase = () => {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
};

export const getUserToken = () => {
  return new Promise((resolve, reject) =>
    getToken(getMessaging(), {
      vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_TOKEN,
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken);
        } else {
          reject(false);
        }
      })
      .catch((err) => {
        reject(err);
      }),
  );
};

export const getProductsFromOrder = (items) => {
  if (!Array.isArray(items)) {
    return [];
  }
  return items.map((item) => ({
    item_name: item.name,
    item_id: `${item.product_id}`,
    price: item.price,
    quantity: item.qty_ordered,
    item_category: '',
    item_brand: '',
  }));
};

export function trackEvent(event, data) {
  const payload = {
    platform_web: isMobile ? 'msite' : 'desktop',
    ...data,
  };
  logEvent(getAnalytics(), event, payload);
}

export const logUserEngagementEvent = () => {
  logEvent(getAnalytics(), 'user_engagement');
};
