import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { setAutoFreeze } from 'immer';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { middlewares } from 'rtk';
import rootSaga from 'store/saga';
import createRootReducer from './mainReducer';

import landersMiddleware from 'containers/Landers/slices/landers/listeners';

import npsListenerMiddleware from 'containers/Home/slices/nps/npsListener';
setAutoFreeze(false);

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();
const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([routerMiddleware(history), sagaMiddleware, ...middlewares]),
});

setupListeners(store.dispatch);
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export { persistor, store };
